<template>
  <div class="status-bar" :style="{ minHeight: barHeight ? barHeight + 'px' : '', backgroundColor: bg }">
  </div>
</template>
<script>
import { getBarHeight } from '@/lib/appMethod'

export default {
  props: {
    bg: {
      type: String,
      default: '#fff'
    },
  },
  data() {
    return {
      barHeight: getBarHeight()
    }
  }
}

</script>

<style lang='less' scoped>
.status-bar {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
</style>
