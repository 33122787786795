<template>
  <div class="container">
    <nav-bar header-title="约课拿勋章" :show-back="true" :header-back="appBack" />
    <div class="row-between-center flex-wrap">
      <div @click="showGroupQuickDialog(item.courseId)" class="course-item" v-for="item in courseList" :key="item.courseId">
        <div class="poster" :style="`background-image: url(${item.coursePic})`"></div>
        <p class="name f26 fw6 ellipsis">{{ item.courseName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, showGroupQuickDialog } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar/nav-bar'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      params: {
        cityId: '',
        venueId: '',
        // medalClockId: '', // 打卡课程ID	
        medalId: '', // 勋章标识
        medalType: '', // 勋章类型,CLOCK_打卡 RANKING_排行 TASK_任务 SPECIAL_限定 CONTEST_好友竞赛
      },
      courseList: [],
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    const pa = getParam()
    this.params.medalId = pa.medalId
    this.params.medalType = pa.medalType
    this.getList()
  },
  methods: {
    appBack: newAppBack,
    getList() {
      this.$axios.post(this.baseURLApp + '/userAppMedal/medalClockCourse', { ...this.params, userId: this.userId, token: this.token }).then((res) => {
        this.courseList = res.data.courseInfoList
      })
    },
    showGroupQuickDialog(id) {
      showGroupQuickDialog(id)
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  padding: calc(130px + env(safe-area-inset-top)) 32px 32px;
  padding: calc(130px + constant(safe-area-inset-top)) 32px 32px;
  background: #F9FAFB;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .course-item {
    width: 336px;
    margin: 32px 0 0;
    border-radius: 8px 8px 0px 0px;
    background-color: #fff;
    overflow: hidden;
    .poster {
      width: 336px;
      height: 256px;
      background-size: cover;
      background-color: #ccc;
    }
    .name {
      height: 70px;
      padding: 12px 24px 0;
      text-align: center;
    }
  }
}
</style>