<template>
  <div class="rank-nav">
    <bar bg="#1E212E" />
    <nav class="part-1 row-between-center">
      <van-icon v-show="isShowTotal" class="back-icon" name="arrow-left" @click="appBack" />
      <van-icon v-show="!isShowTotal" class="back-icon" name="arrow-left" @click="fakeBack" />
      <div class="col-start-center" v-show="title">
        <p class="title f28 fw6">{{ title }}</p>
        <p class="sub-title f22">榜单更新可能存在延迟</p>
      </div>
      <img @click="showShare = true" class="back-icon" src="https://img.chaolu.com.cn/ACT/double11-2022/index/share-icon.png" alt="">
    </nav>
    <div class="part-2 row-between-center">
      <div class="tab-box f24 row-center-center">
        <div :class="['tab', 'row-center-center', sightType === 0 ? 'active' : '']" @click="switchPlace(0)">
          门店</div>
        <div :class="['tab', 'row-center-center', sightType === 0 ? '' : 'active']" @click="switchPlace(1)">
          城市</div>
      </div>
      <div class="name-box row-center-center f24" @click="choosePlace">
        {{ sightType === 0 ? curPlace.venueName : curPlace.cityName }}&nbsp;
        <van-icon name="arrow-down" />
      </div>
    </div>

    <city-venue ref="cityVenue" :sightType="sightType" @change="venueChange"></city-venue>
    <!--  分享  -->
    <common-share
      :value="showShare"
      :shareParams="shareParams"
      :shareItem="['minifriend']"
      @close="showShare = false"
      @share-success="showShare = false"
    >
    </common-share>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, hideAppBackBtn } from '@/lib/appMethod'
import cityVenue from '@/components/cl-city-venue/index202207'
import bar from '@/components/bar'
import commonShare from "@/components/commonShare"
import { getParam } from '@/lib/utils'
import wx from "weixin-js-sdk"

export default {
  mixins: [userMixin],
  props: ['title', 'isShowTotal', 'from'],
  components: {
    cityVenue, bar, commonShare
  },
  data() {
    return {
      sightType: 0,
      realPlace: {}, // 定位拿到的城市门店 没有就默认福州市、中庚青年广场店
      curPlace: {
        venueId: 1,
        cityId: '3',
        cityName: '',
        venueName: ''
      }, // 当前选择的城市或门店

      showShare: false,
      shareParams: {
        title: '运动达人排行榜',
        miniImage: 'https://img.chaolu.com.cn/MINI/minicard/1.png',
        path: `/pages/webView/index?webUrl=${window.location.origin}/#/medal/rank`,
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
        url: 'url',
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },
    }
  },

  async created() {
    await this.$getAllInfo(['venueId', 'cityId'])
    hideAppBackBtn()
    initBack()

    // 是城市还是门店视角
    const isCity = getParam().isCity
    if (isCity) {
      this.sightType = 1
    }

    const r = await this.getVenueInfo()

    this.curPlace = {
      venueId: this.venueId,
      cityId: this.cityId,
      cityName: r.data.cityName,
      venueName: r.data.venueName
    }

    this.$emit('venueChange', this.curPlace)

    const uu = encodeURIComponent(`${window.location.origin}/#/medal/rank`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    wx.miniProgram.postMessage({
      data: {
        type: 'share',
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    })
  },
  methods: {
    appBack: newAppBack,
    getVenueInfo() {
      return this.$axios.post(this.baseURLApp + '/newVenue/getVenueInfo', {
        venueId: this.venueId
      })
    },
    switchPlace(type) {
      this.sightType = type
      this.$emit('rangeTypeChange', type)
    },
    choosePlace() {
      this.$refs.cityVenue.showPicker()
    },
    venueChange(v) {
      console.log('hhha', v)
      if (this.sightType === 0) {
        this.curPlace.venueName = v.name
      }
      if (this.sightType === 1) {
        this.curPlace.cityName = v.name
      }
      this.$emit('venueChange', v)
    },
    fakeBack() {
      if (this.from) {
        newAppBack()
      } else {
        this.$emit('sightChange')
      }
    }
  },
}
</script>

<style lang='less' scoped>
* {
  box-sizing: border-box;
}

.rank-nav {
  // position: fixed;
  // top: 0;
  width: 100%;
  background: #1E212E;
  padding: 0 32px 0;
  padding: 0 32px 0;
  color: #fff;

  .part-1 {
    padding: 26px 0;
    min-height: 122px;
    .van-icon {
      font-size: 48px;
    }

    .sub-title {
      color: #9AA1A9;
    }
  }


  .part-2 {
    padding: 32px 0;

    .tab-box {
      width: 192px;
      height: 50px;
      border-radius: 8px;
      border: 1px solid #DDDDDD;
      overflow: hidden;

      .tab {
        width: 50%;
        height: 100%;
        color: #ddd;

        &.active {
          color: #fff;
          background: rgba(255, 222, 0, .5);
        }
      }
    }

    .name-box {
      color: #ddd;
    }
  }

}
</style>
