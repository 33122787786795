<template>
  <van-popup v-model="show" round position="bottom" @close="closePicker">
    <van-picker show-toolbar :columns="columns" @cancel="show = false" @confirm="onConfirm" />
  </van-popup>
</template>
<script>
export default {
  props: ['sightType', 'cityId'],
  data() {
    return {
      show: false,

      cityTree: [], // 用于级联选择器的树状结构
      venueTree: [], // 用于级联选择器的树状结构
      venueList: [], // 门店列表
      columns: [],

    }
  },
  watch: {
    // 0门店 1城市
    sightType(v) {
      if (v === 0) {
        this.switchSightType('venue')
      } else {
        this.switchSightType()
      }
    }
  },
  created() {
    this.getCityVenue()
  },
  methods: {
    showPicker() {
      this.show = true
    },
      closePicker() {
          this.$emit('closed')
      },
    // picker 确认操作
    onConfirm(v) {
      // 当门店时 v:['福州市', '鼓楼区', '中庚青年广场']
      // 当城市时 v:{id: xxx, name: xxx, label:xxx ....}
      // 对父组件吐出相同的数据结构  {id: xxx, name: xxx, label:xxx ....}
      let venue = v
      if (Array.isArray(v)) {
        venue = this.venueList.filter((item) => item.label === v[2])[0]
      }
      this.show = false
      this.$emit('change', venue) // 此时的venue可能为city对象 也可能为venue对象
    },
    // 获取门店数据
    getCityVenue() {
      this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then((res) => {
        res.data[0].children.map((province) => {
          province.children.map((c) => {
            // 处理城市列表
            const city = JSON.parse(JSON.stringify(c))
            city.text = city.label
            delete city.children
            this.cityTree.push(city)
            // 处理门店列表
            c.text = c.label
            c.children.map((area) => {
              area.text = area.label
              area.children.map((venue) => {
                venue.text = venue.label
                delete venue.children
                this.venueList.push(venue)
              })
            })
            this.venueTree.push(c)
          })
        })
          if(this.sightType === 0){
              this.switchSightType('venue')
          }else if(this.sightType === 1){
              this.switchSightType()
          }

      })
    },
    // 处理门店数据
    switchSightType(type) {
      if (type === 'venue') {
        this.columns = this.venueTree
      } else {
        this.columns = this.cityTree
          if(this.cityId){
            let cityItem = this.columns.find((v) => {return v.id == this.cityId})
            this.$emit('cityItem', cityItem) // 抛出选项
          }
      }

    }

  },

}
</script>

<style lang='less' scoped>
/deep/.van-picker-column:nth-child(3) {
  flex: 1.5;
}
</style>
