<template>
  <div class="container">
    <van-sticky>
      <nav-bar :isShowTotal="isShowTotal" :from="pageFrom" :title="title" @rangeTypeChange="rangeTypeChange" @venueChange="venueChange"
        @sightChange="isShowTotal = true" />
      <div v-show="!isShowTotal" class="nav-rank col-start-center">
        <div class="my-rank">
          <div class="rank-item row-start-center">
            <span class="t1 flex-none f30 BebasNeueBold">{{ userRankInfo.rankSeqNo }}</span>
            <img @click="goUserPage(userRankInfo)" class="avator flex-none" :src="userRankInfo.headImg" alt="">
            <span class="t2 f28 ellipsis">{{ userRankInfo.nickName }}</span>
            <p class="t3 flex-none col-start-end flex-none">
              <span class="t4 f32 fw6 BebasNeueBold">{{ userRankInfo.rankValueContent }}</span>
              <span class="t5 f24">{{ typeList[businessTypeIndex].unit }}</span>
            </p>
          </div>
        </div>
      </div>
    </van-sticky>
    <!-- 分条线达人榜单 -->
    <div class="total-block" v-show="isShowTotal">
      <div class="line-title puhui f52 col-start-center">
        冲击{{ month }}月运动达人
        <p class="f80">TOP{{ params.rankRangeType === 0 ? 50 : 200 }}</p>
      </div>
      <div class="row-between-center flex-wrap">
        <div class="card-item" @click="lookDetail(item.businessType, index)" v-for="item, index in typeList"
          :key="index">
          <div class="t1 f28 row-between-center">
            <span>{{ typeList[index].name }}达人</span>
            <van-icon name="arrow" />
          </div>
          <div class="t2 row-start-end f24">
            最高&nbsp;
            <span class="f52 BebasNeueBold"> {{ totalData[typeList[index].key][0] ? totalData[typeList[index].key][0].rankValueContent : 0 }} </span>
            &nbsp;{{ typeList[index].unit }}
          </div>
          <div class="t3 flex flex-wrap">
            <div class="avator flex-none" :style="`background-image: url(${item.headImg})`"
              v-for="item in totalData[typeList[index].key].slice(0, 10)" :key="item.rankSeqNo"></div>
            <div class="avator flex-none"
              v-for="item in (totalData[typeList[index].key].length > 10 ? 0 : 10 - totalData[typeList[index].key].length)"
              :key="item + 100"></div>
          </div>
        </div>

      </div>
    </div>
    <!-- 具体排行 -->
    <div class="detail-block" v-show="!isShowTotal">
      <div class="rank-item row-start-center" v-for="item in rankList" :key="item.userId">
        <span class="t1 f30 BebasNeueBold">{{ item.rankSeqNo }}</span>
        <img @click="goUserPage(item, 2)" class="avator flex-none" :src="item.headImg" alt="">
        <span class="t2 f28 ellipsis">{{ item.nickName }}</span>
        <p class="t3 col-start-end flex-none">
          <span class="t4 f32 fw6 BebasNeueBold">{{ item.rankValueContent }}</span>
          <span class="t5 f24">{{ typeList[businessTypeIndex].unit }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, goUserPage, loginOut } from '@/lib/appMethod'
import navBar from './components/rank-nav'
import { getParam } from '../../lib/utils'

export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      userInfo: { // 用户信息
        qrCode: '',
        nickName: '',
        headImg: '',
      },
      typeList: [
        { name: '运动时长', key: 'ticketTimeUserRankInfo', businessType: 'TICKET_TIME', unit: 'min' },
        { name: '高频运动', key: 'ticketCountUserRankInfo', businessType: 'TICKET_COUNT', unit: '次' },
        { name: '团课', key: 'scheduleUserRankInfo', businessType: 'SCHEDULE', unit: '节' },
        { name: '私教', key: 'perUserRankInfo', businessType: 'PER', unit: '节' },
        { name: '小班课', key: 'microUserRankInfo', businessType: 'MICRO', unit: 'Kcal' }
      ],
      params: {
        businessType: '', // 业务类型,TICKET_COUNT_自助次数  TICKET_TIME_自助分钟数  SCHEDULE_团课  PER_私教  MICRO_小班课
        cityId: '',
        venueId: '',
        rankRangeType: 0, // 0门店  1城市
        page: {
          current: 1,
          size: 50,
        }
      },
      businessTypeIndex: 0, // 当前查看的第几个排行
      totalData: {
        rankingMonth: '',
        ticketTimeUserRankInfo: [],
        ticketCountUserRankInfo: [],
        scheduleUserRankInfo: [],
        perUserRankInfo: [],
        microUserRankInfo: []
      },

      userRankInfo: {}, // 个人排名,
      rankList: [],
      isShowTotal: true, // 是否展示 月度达人汇总
      pageFrom: '',
    }
  },
  computed: {
    month() {
      const str = this.totalData.rankingMonth.slice(-2)
      return str[0] === '0' ? str.slice(-1) : str
    },
    title() {
      return this.isShowTotal ? '' : `${this.month}月${this.typeList[this.businessTypeIndex].name}排行`
    },


  },
  async created() {
    initBack()
    // 来自其他页面
    this.pageFrom = getParam().from
    // 是城市还是门店视角
    const isCity = getParam().isCity
    if (isCity) {
      this.params.page.size = 200 // 门店前50 城市前200
      this.params.rankRangeType = 1 // 0门店  1城市
    }
    // 首次进入 判断是达人 还是 单项排名
    const businessType = getParam().businessType
    if (businessType) {
      this.isShowTotal = false

      this.typeList.map((item, index) => {
        if (item.businessType === businessType) {
          this.params.businessType = businessType
          this.businessTypeIndex = index
        }
      })
    }
  },
  methods: {
    appBack: newAppBack,
    goUserPage(obj, type) {
      if (obj.privacyStatus === 1) return // 用户开启匿名
      goUserPage(obj.userId, type)
    },
    getTotal() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: 'spinner',
      })
      this.$axios.post(this.baseURLApp + '/userAppMedal/monthRanking', { ...this.params, userId: this.userId, token: this.token }).then((res) => {
        this.$toast.clear()
        this.totalData = res.data
      }).catch(() => {
        loginOut()
      })
    },
    getRankList() {
      // this.$toast.loading({
      //   duration: 0,
      //   forbidClick: true,
      //   overlay: true,
      //   loadingType: 'spinner',
      // })
      this.$axios.post(this.baseURLApp + '/userAppMedal/monthMedalRank', { ...this.params, userId: this.userId == 1 ? null : this.userId, token: this.token }).then((res) => {
        this.$toast.clear()
        this.userRankInfo = res.data.userRankInfo
        this.rankList = res.data.page.records
      })
    },
    lookDetail(type, index) {
      this.params.businessType = type
      this.businessTypeIndex = index
      this.isShowTotal = false
      this.getRankList()
    },

    async venueChange(v) {
      await this.$getUserId()
      if (!this.params.venueId) {
        this.params.venueId = v.id
      }
      if (!this.params.cityId) {
        this.params.cityId = v.cityId
      }
      this.params.rankRangeType === 0 ? (this.params.venueId = v.venueId) : (this.params.cityId = v.cityId || v.id)

      this.getTotal()
      this.isShowTotal ? null : this.getRankList()
    },
    rangeTypeChange(v) {
      this.params.page.size = v === 0 ? 50 : 200 // 门店前50 城市前200
      this.params.rankRangeType = v
      this.getTotal()
      this.isShowTotal ? null : this.getRankList()
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  background: #1E212E;
  color: #fff;
  padding: 0 0 5vh;
  min-height: 95vh;

  * {
    box-sizing: border-box;
  }

  .total-block {
    padding: 0 28px;
    // min-height: 100vh;
  }

  .detail-block {
    padding: 0 28px;
    // min-height: 100vh;
  }

  .line-title {
    margin: 50px 0 0;

    p {
      color: #FFCC02;
    }
  }

  .card-item {
    width: 336px;
    height: 390px;
    background: linear-gradient(180deg, #1D212D 0%, #262C3C 100%);
    border-radius: 24px;
    border: 1px solid #383E4B;
    padding: 32px 28px;
    margin: 38px 0 0;

    .t2 {
      margin: 40px 0 44px;
      color: #9AA1A9;
      span {
        color: #FFCC02;
        transform: translateY(10px);
      }
    }

    .t3 {
      .avator {
        width: 43px;
        height: 43px;
        border-radius: 50%;
        margin: 20px 14px 0 0;
        background-color: #384156;
        background-size: cover;
        background-position: center;

        &:nth-child(5n) {
          margin: 20px 0 0 0;
        }
      }
    }
  }

  .nav-rank {
    padding: 36px 0;
    background: #1E212E;

    .my-rank {
      width: 686px;
      background: #32384B;
      border-radius: 12px;
    }
  }

  .rank-item {
    width: 100%;
    height: 108px;
    padding: 0 28px;

    .t1 {
      margin: 10px 38px 0 0;
    }

    .avator {
      width: 72px;
      height: 72px;
      border-radius: 50%;
      margin: 0 16px 0 0;
    }

    .t3 {
      margin: 0 0 0 auto;
      text-align: right;

      .t4 {
        color: #FCC926;
      }

      .t5 {
        color: #9AA1A9;
      }
    }
  }
}
</style>f
