//该页面是 其他人的勋章墙
<template>
  <div class="container">
    <div class="nav row-between-center" :style="{'padding-top': andBar }">
      <van-icon name="arrow-left" @click="appBack" />
      <div class="col-start-center">
        <div class="row-start-center">
          <img :src="userInfo.headImg" alt="" class="avator">
          <p class="row-start-center"><span class="name ellipsis">{{ userInfo.nickName }}</span>的勋章墙</p>
        </div>
        <p class="f24">共{{ medalTotal }}枚</p>
      </div>
      <van-icon name="arrow-left" style="opacity: 0;" />
    </div>
    <van-list v-model="loading" :finished="finished" :finished-text="medalList.length ? '没有更多了' : 'ta还未获得勋章哦~'" :immediate-check="false" @load="getList">
      <div class="content row-between-center flex-wrap">
        <medal-item :medal="medal" v-for="medal in medalList" :key="medal.clockMedalId"></medal-item>
      </div>
    </van-list>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, getBarHeight } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

import medalItem from './components/medal-item'
export default {
  mixins: [userMixin],
  components: {
    medalItem
  },
  data() {
    return {
      loading: false,
      finished: false,

      userInfo: { // 用户信息
        nickName: '',
        headImg: '',
      },
      params: {
        page: {
          size: 20,
          current: 1,
        },
        userId: '', // 别人的userId
      },
      medalList: [],
      medalTotal: 0,
      barHeight: 0,
    }
  },
  computed: {
    andBar() {
      if (this.appTypeStr === 'and') {
        return this.barHeight + 'px'
      }
      return undefined
    }
  },
  async created() {
    await this.$getAllInfo()
    initBack()
    
    const pa = getParam()

    this.params.userId = pa.userId
    if (pa.userId) {
      this.getList()
    }
    console.log('userHead', pa.userHead);
    console.log('userName', pa.userName);
    this.userInfo = {
      nickName: decodeURIComponent(pa.userName),
      headImg: decodeURIComponent(pa.userHead)
    }
  },
  mounted() {
    this.barHeight = getBarHeight()
    console.log('barHeight', this.barHeight);
  },
  methods: {
    appBack: newAppBack,
    getList() {
      this.$axios.post(this.baseURLApp + '/userAppMedal/userAchieveMedalList', { ...this.params }).then((res) => {
        const r = res.data.medalInfoPage.records
        this.medalList = this.medalList.concat(r)
        this.medalTotal = res.data.medalInfoPage.total
        this.loading = false
        this.params.page.current++
        if (r.length < this.params.page.size) {
          this.finished = true
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.container {

  background: #18202D;
  padding-top: calc(130px + constant(safe-area-inset-top));
  padding-top: calc(130px + env(safe-area-inset-top));
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }

  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100px;
    z-index: 110;
    background: #18202D;
    color: #fff;
    padding: constant(safe-area-inset-top) 24px 12px;
    padding: env(safe-area-inset-top) 24px 12px;
    .van-icon {
      font-size: 42px;
    }
    .avator {
      width: 52px;
      border-radius: 50%;
      margin: 0 12px 0 0;
    }

    .name {
      max-width: 350px;
    }
  }
  .content {
    margin: 0 0 100px;
    padding: 0 36px;
    &:after {content: ''; width: 33%; flex: auto; }
  }
}
</style>