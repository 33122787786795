//该页面是 新上勋章
<template>
  <div class="container">
    <div class="nav row-between-center" :style="`top: ${barHeight || 0}px;`">
      <van-icon name="arrow-left" @click="appBack" />
      <div class="row-start-center">
        <p class="row-start-center">新上勋章</p>
      </div>
      <van-icon name="arrow-left" style="opacity: 0;" />
    </div>
    <div class="content row-between-center flex-wrap">
      <medal-item size="big" :medal="medal" v-for="medal in medalList" :key="medal.medalId"></medal-item>
    </div>
    <p v-if="!medalList.length" class="tip f28">暂无数据</p>

  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, getBarHeight } from '@/lib/appMethod'
import medalItem from './components/medal-item'
export default {
  mixins: [userMixin],
  components: {
    medalItem
  },
  data() {
    return {
      medalList: [],
      barHeight: 0,
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    this.getList()
  },
  mounted() {
    this.barHeight = getBarHeight()
  },
  methods: {
    appBack: newAppBack,
    getList() {
      this.$axios.post(this.baseURLApp + '/userAppMedal/userAppMedalThemeList', { userId: this.userId, isLatest: 1, page: 1, rows: 1000 }).then((res) => {
        this.medalList = res.data.medalInfoList
      })
    }
  },
}
</script>
<style lang="less" scoped>
.container {

  background: #18202D;
  padding-top: calc(130px + constant(safe-area-inset-top));
  padding-top: calc(130px + env(safe-area-inset-top));
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  .nav {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100px;
    z-index: 110;
    background: #18202D;
    color: #fff;
    padding: constant(safe-area-inset-top) 24px 0;
    padding: env(safe-area-inset-top) 24px 0;
    .van-icon {
      font-size: 42px;
    }

    .avator {
      width: 52px;
      margin: 0 12px 0 0;
    }

    .name {
      max-width: 400px;
    }
  }

  .content {
    margin: 0 0 100px;
    padding: 0 36px;

    &:after {
      content: '';
      width: 33%;
      flex: auto;
    }
  }

  .tip {
    width: 100%;
    margin: 150px 0 0;
    color: #969799;
    text-align: center;
  }
}
</style>