<template>
  <div class="container">
    <nav-bar header-title="约课拿勋章" :show-back="true" :header-back="appBack" />
    <div class="row-between-center flex-wrap">
      <div @click="goPriDetail(item.courseId)" class="course-item row-start-end" :style="`background-image: url(${item.coursePic})`" v-for="item in courseList" :key="item.courseId">
        <p class="name f48 fw6 ellipsis">{{ item.courseName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, goPriDetail } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar/nav-bar'

export default {
  mixins: [userMixin],
  components: {
    navBar,
  },
  data() {
    return {
      params: {
        cityId: '',
        venueId: '',
        // medalClockId: '', // 打卡课程ID	
        medalId: '', // 勋章标识
        medalType: '', // 勋章类型,CLOCK_打卡 RANKING_排行 TASK_任务 SPECIAL_限定 CONTEST_好友竞赛
      },
      courseList: [],
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo()
    initBack()

    const pa = getParam()
    this.params.medalId = pa.medalId
    this.params.medalType = pa.medalType
    this.getList()
  },
  methods: {
    appBack: newAppBack,
    goPriDetail,
    getList() {
      this.$axios.post(this.baseURLApp + '/userAppMedal/medalClockCourse', { ...this.params, userId: this.userId, token: this.token }).then((res) => {
        this.courseList = res.data.courseInfoList
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: calc(130px + env(safe-area-inset-top)) 32px 32px;
  padding: calc(130px + constant(safe-area-inset-top)) 32px 32px;
  background: #F9FAFB;
  * {
    box-sizing: border-box;
  }
  .course-item {
    width: 686px;
    height: 420px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6000) 100%);
    border-radius: 16px;
    margin: 32px 0 0;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    padding: 40px 56px;
    .name {
      color: #fff;
    }
  }
}
</style>