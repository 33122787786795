<template>
  <div :class="['medal-item', 'col-start-center', size]">
    <img :src="medal.medalPic" :class="['pic', size]" alt="">
    <p class="name f22 ellipsis">{{ medal.medalAward || medal.medalName }}</p>
    <p class="date f20">{{ medal.gainDate }}</p>
  </div>
</template>
<script>
export default {
  props: {
    medal: {
      type: Object
    },
    size: {
      type: String,
      value: 'small'
    }
  },
}
</script>

<style lang='less' scoped>
.medal-item {
  margin: 80px 0 0;
  justify-self: flex-start;
  
  .pic {
    width: 192px;
    &.big {
      width: 267px;
    }
  }
  .name {
    max-width: 192px;
    color: #fff;
    margin: 12px 0 0;
  }
  .date {
    margin: 6px 0 0;
    color: #9AA1A9;
  }
}
</style>